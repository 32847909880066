<template>
  <section>
    <div class="text-h3 title">
      {{ $t("status") }}
    </div>
    <div class="content-boxes">
      <v-card :loading="loading">
        <div class="inner-content">
          <div class="label">
            {{ $t("charged_remain") }}
          </div>
          <div class="value">
            {{
              item.state_of_charge !== "N/A"
                ? `${item.state_of_charge}%`
                : item.state_of_charge
            }}
          </div>
        </div>
      </v-card>
      <!-- <v-card :loading="loading">
        <div class="inner-content">
          <div class="label">
            {{ $t("time_left") }}
          </div>
          <div class="value">
            {{ item.time_left }}
          </div>
        </div>
      </v-card> -->
      <v-card :loading="loading">
        <div class="inner-content">
          <div class="label">
            {{ $t("range") }}
          </div>
          <div class="value">
            {{ item.remaining_km }}
          </div>
        </div>
      </v-card>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.title
  font-weight: 600
.content-boxes
  margin-top: 1rem
  display: flex
  gap: 1rem
  .v-card
    flex: 1
    .inner-content
      padding: 1rem
      .value
        font-weight: 600
</style>
